.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.contact-container > * {
  max-width: 2000px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}

.contact-title {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form {
  width: 400px;
  max-width: 100%;
}

.icon-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px; /* Adjust the gap between cards */
}

.social-media-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px; /* Reduce padding to make the card thinner */
  max-width: 300px; /* Set a maximum width for the card */
  width: 100%; /* Make sure the card fills the container's width */
}
