.NavBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.NavButton {
    min-width: 50px;
    max-width: 100px;
    min-height: 60px;
    max-height: 80px;
    flex: 1;
    text-align: center;
}

.navText {
    font-size: 1.25em;
    color: black;
    display: block;
    text-decoration: none;
}



/* Hamburger menu styling */
.hamburger {
    display: none; /* Hide by default */
    font-size: 30px; /* Larger font size for the hamburger menu */
    color: black; /* Black color for the hamburger menu */
    cursor: pointer; /* Cursor changes to pointer on hover */
    padding: 15px 0; /* 15px padding above and below */
}

/* Up arrow button styling */
.up-arrow {
    display: none; /* Hide by default */
    font-size: 30px; /* Larger font size for the up arrow */
    color: black; /* Black color for the up arrow */
    cursor: pointer; /* Cursor changes to pointer on hover */
    padding: 15px 0; /* 15px padding above and below */
}

/* Media query for larger displays */
@media screen and (min-width: 768px) {
    .NavBox {
        gap: 20px;
    }

    .NavButton {
        min-width: 80px;
        max-width: 150px;
        min-height: 80px;
        max-height: 100px;
    }

    .navText {
        font-size: 1.5em;
    }
}

/* Media query for smaller screens (e.g., phones) */
@media screen and (max-width: 480px) {
    .NavBox {
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .NavButton {
        min-width: 100%;
        max-width: 100%;
        min-height: 60px;
        max-height: 80px;
    }

    .navText {
        font-size: 1em;
    }
    
    .hamburger {
        display: block; /* Show the hamburger menu on small screens */
    }
    
    .NavBox.expanded .up-arrow {
        display: block; /* Show the up arrow when menu is expanded */
    }

    /* Hide the menu by default on smaller screens */
.NavBox.collapsed .NavButton {
    display: none;
}

/* Show the menu when expanded on smaller screens */
.NavBox.expanded .NavButton {
    display: block;
}
}
