.app-shell-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .app-shell-content {
    flex: 1;
  }

  .scrollable-container {
    flex: 1;
    overflow-y: auto;
  }
  

  .testing-alert {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
  }
  