.social-media {
    margin: 1rem 0;
  }
  
  .social-media a i {
    margin: 0.5rem;
  }
  
  .social-media a i.fa-facebook {
    color: #4267B2;
  }
  
  .social-media a i.fa-twitter {
    color: #1DA1F2;
  }
  
  .social-media a i.fa-instagram {
    color: #E4405F;
  }
  
  .social-media a i.fa-pinterest {
    color: #BD081C;
  }
  
  .social-media a i.fa-youtube {
    color: #FF0000;
  }
  
  .social-media a i.fa-tiktok {
    color: #000000;
  }
  
  .social-media a i.fa-etsy {
    color: #F56400;
  }
  