/* Home.css */
.home-card {
  width: 300px;
}

.home-media {
  height: 150px;
  background-size: cover;
  background-position: center;
}

.home-content {
  height: 150px;
  overflow: hidden;
}

.home-imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  padding-bottom: 50px;
}

.home-text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-container {
  vertical-align: bottom;
}

.home-image {
  width: auto; /* Ensure the image scales to the container's width */
  max-width: 100%; /* Adjust the maximum width as needed */
  height: auto; /* Allow the image to maintain its aspect ratio */
  max-height: 400px;
  display: block; /* Remove any potential inline spacing */
  margin: 0 auto; /* Center the image horizontally within its container */
  padding: 10px; /* Add some padding around the image if desired */
}

/* Define the class for the avatar images */
.home-avatar {
  width: 100%; /* Ensure the image scales to the container's width */
  max-width: 400px; /* Adjust the maximum width as needed */
  height: auto; /* Allow the image to maintain its aspect ratio */
  display: block; /* Remove any potential inline spacing */
  margin: 0 auto; /* Center the image horizontally within its container */
  padding: 10px; /* Add some padding around the image if desired */
}

.row {
  display: flex;
  margin-bottom: 40px;
}

.column {
  flex: 50%;
  padding: 5px;
}


/* Define a class for the "Find out more" buttons */
.home-button {
  text-align: center;
  margin-top: 20px; /* Add some margin to separate the buttons from content */
}

/* Center text horizontally within grid items */
.home-text-center {
  text-align: center;
}
