/* Animation.css */
.animation-card {
    display: flex;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
  }
  
  .intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .intro-container > * {
    max-width: 800px;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .intro-container > *:last-child {
    margin-bottom: 0;
  }
  