/* New Layout Container */
.testimonialLayout {
    display: flex;
    align-items: flex-start; /* Align to the top */
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 0px; /* Space between logo and testimonials */
  }
  
  /* Logo Image */
  .websiteLogo {
    max-width: 200px; /* Adjust as per your logo size */
    margin-top: 15px;
    height: auto;
  }
  
  /* Speech Bubble for Testimonials */
  .speechBubble {
    flex: 3;
    padding: 20px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
    border-radius: 15px; /* Rounded corners for the bubble */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between each testimonial */
  }
  
  /* Existing styles for reviewBox */
  .reviewBox {
    border: none;
    box-shadow: none;
    background-color: transparent; /* Remove background */
  }
  
  .reviewContent, .reviewFooter {
    background-color: transparent; /* Remove background for transparency */
  }
  